<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize" @submit.native.prevent>
        <el-form-item label="车牌号" prop="number">
          <el-input
            size="mini"
            v-model.trim="table.params.number"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">添加</el-button>
      </div>
    </div>
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showIndex="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <!-- 车牌号 -->
      <template v-slot:number="{row}">
        <div class="flex">
          <el-image
          style="width: 60px; height: 60px;margin-right:10px;"
          :src="row.image"
          fit="cover"
          :preview-src-list="[row.image]"></el-image>
          <span>{{row.number}}</span>
        </div>
      </template>
      <!-- 运输温度 -->
      <template v-slot:insulation_level="{row}">
        <span>{{toStr({1:'常温(环境温度)',2:'冷藏(0~-10℃)',3:'冷冻(-10℃~-22℃)'},row.insulation_level)}}</span>
      </template>
      <!-- 车辆状态 -->
      <template v-slot:show_status="{row}">
        <span>{{ toStr({1:'空闲中', 2:'已禁用', 3:'使用中'},row.show_status)}}</span>
      </template>
      <!-- 路线数量 -->
      <template v-slot:store_count="{row}">
        <el-link type="primary" style="padding:0 10px;" @click="handleStore(row)">{{row.store_count}}</el-link>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="showRecords(row)">领用记录</el-button>
        <el-dropdown @command="handleCommand($event,row)" style="margin-left:10px">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="insurance">车辆保险</el-dropdown-item>
            <el-dropdown-item command="options">{{row.show_status == 2 ? '启用' : '禁用'}}</el-dropdown-item>
            <el-dropdown-item command="remove" v-if="is_sys == 1">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </VTable>

    <!-- 修改食堂路线 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="选择食堂" prop="store_ids">
          <el-select v-model="form.data.store_ids" multiple filterable @focus="getStore" style="width:100%;margin:10px 0;">
            <el-option v-for="item in storeArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handleRemark">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 创建、编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>
    <!-- 领用记录 -->
    <records ref="records" @refresh="getTable"></records>
    <!-- 保险记录 -->
    <insurance ref="insurance" @refresh="getTable"></insurance>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Records from './components/Records.vue'
import Insurance from './components/Insurance.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import {getStorage} from '@/storage'

export default {
  name: 'CarsIndex',
  components: {
    Edit,
    Records,
    Insurance,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      province: getStorage('province'),  // 有地区就是区账号
      city: getStorage('city'),  // 有地区就是区账号
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      is_sys: this.$store.state.is_sys, // 1:只有超级管理员才有列表的删除按钮
      loading: false,
      field: [
        { name: "number", label: "车牌号", width:'180', hidden: false },
        { name: "brand", label: "品牌/车型", hidden: false },
        { name: "color", label: "车身颜色", hidden: false},
        { name: "insulation_level", label: "运输温度", hidden: false},
        { name: "insurance_end_day", label: "保险到期日", hidden: false},
        { name: "store_count", label: "路线数量", width:'110',  hidden: false},
        { name: "lastly_rider_name", label: "上一领用人", width:'110',  hidden: false},
        { name: "show_status", label: "车辆状态", width:'110',  hidden: false},
        { name: "create_time", label: "创建时间",  width:'160', hidden: false},
        { name: "action", label: "操作",width: "180", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          number: '', // 车牌号
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      storeArr: [], //食堂

      dialogVisible: false,
      form: {
        loading: false,
        data: {
          store_ids: [], 
        },
        rules: {
          // store_ids: [{ required: true, message:'请选择', trigger: ['change','blur'] }],
        }
      },
    }
  },
  created() {
    this.getTable();
  },
  mounted() {
    // 区账号隐藏路线数量字段
    if(!!this.province || !!this.city ) {
      this.field.splice(5, 1); // 删除第6项下标5 (路线数量)
    }
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/cars/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 保险
    handleInsurance(row) {
      let dom = this.$refs.insurance;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null;
    },
    // 编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 领用记录
    showRecords(row) {
      let dom = this.$refs.records;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null;
    },
    // 禁用/启用
    handleOptions(row) {
      //  //展示状态：1-空闲中；2-已禁用；3-使用中；
      let str = row.show_status == 2 ?  '启用' :  '禁用'
      this.setConfirm(
        `确认${str}操作？`,
        {  id: row.id },
        "/admin/cars/updateStatus"
      );
    },
    // 取消
    handleCancel(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/cars/delete"
      );
    },
    // 获取食堂
    getStore() {
      this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
        if(res.code === 1) {
          this.storeArr = res.data.list;
        }
      })
    },
    // 获取食堂
    async handleStore(row) {
      await this.getStore();
      this.dialogVisible = true;
      this.form.data.id = row.id;
      this.form.data.store_ids = !!row.store_ids ? row.store_ids.split(",").map(Number) : [];
    },
    // 修改食堂
    handleRemark() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {
            id: this.form.data.id, // 采购单id
            store_ids: this.form.data.store_ids.join(","), // 食堂ids
          }
          this.form.loading = true;
          this.$http.post('/admin/cars/bindStore', _params).then(res => {
            if(res.code == 1) {
              this.dialogVisible = false;
              this.$message.success("操作成功！")
              this.getTable(); // 更新列表
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.$refs.elFormDom.resetFields();
    },
    handleCommand(value,row) {
      if(value == 'insurance') { // 车辆保险
        this.handleInsurance(row)
      } else if(value == 'options') { // 禁用/启用
        this.handleOptions(row)
      } else if(value == 'remove') { // 删除
        this.handleCancel(row)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  align-items: center;
}
.el-dropdown-link {
  cursor: pointer;
  color: #2cb167;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.main-view {
  overflow: auto;
}
.main-view::-webkit-scrollbar {
  width:0px;
}
.main-view::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 0px;
}
</style>
